import React from "react";
import Login from "./Component/Login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Dashboard from "./Component/Dashboard/Dashboard";
import Staticfunction from "./Component/Static/Staticfunction";
import Errorpage from "./Component/404-Error-Page/Errorpage";
import CanticateDetails from "./Component/Candicate-Details-Form/Canticate";
import FileList from "./Component/Dashboard/FileList";
function App() {
  const { logout, setlogout, otppopup, setotppopup, email, setemail } =
    Staticfunction();
  const dynamicprops = {
    logout,
    setlogout,
    otppopup,
    setotppopup,
    email,
    setemail,
  };

  const PrivateRoute = () => {
    return sessionStorage.getItem("email") ? <Outlet /> : <Navigate to="/" />;
  };
  const AuthRoute = () => {
    return sessionStorage.getItem("email", email) ? (
      <Navigate to="/dashboard" replace />
    ) : (
      <Outlet />
    );
  };

  return (
    <>
      <Router>
        <Routes>
          <Route element={<AuthRoute />}>
            <Route path="/" element={<Login {...dynamicprops} />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="*" element={<Errorpage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/candidate-filelist" element={<FileList />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/candidate-details" element={<CanticateDetails />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
