import React from "react";
import vylogo from "../image/vy-logo.png";
import json from "../Tabilelist.json";
import "../styles/FileList.css";
import { Grid, Button, TextField, InputAdornment, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AiTwotoneFile } from "@react-icons/all-files/ai/AiTwotoneFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import ReactPaginate from "react-paginate";
import SearchIcon from "@mui/icons-material/Search";
// import img from "../image/223.jpg";
import ReactPdf from "../assets/React.pdf";
function FileList() {
  const clearsession = () => {
    sessionStorage.clear("email");
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [selectedButton, setSelectedButton] = React.useState("");
  const [isDownloadPage, setIsDownloadPage] = React.useState(false);
  const [nameFilter, setNameFilter] = React.useState("");
  const [emailFilter, setEmailFilter] = React.useState("");
  const [countryFilter, setCountryFilter] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(
    json[0].Candidate_Details
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const itemsPerPage = 4;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  const handleClickOpen = (scrollType, buttonType) => () => {
    setOpen(true);
    setScroll(scrollType);
    setSelectedButton(buttonType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const getDialogTitle = () => {
    if (selectedButton === "i9") {
      return "I-9 Details";
    } else if (selectedButton === "msa") {
      return "MSA Details";
    } else if (selectedButton === "w9") {
      return "W9 Details";
    } else if (selectedButton === "visa") {
      return "Visa Details";
    } else if (selectedButton === "coi") {
      return "COI Details";
    } else if (selectedButton === "countersign") {
      return "Countersign Details";
    }

    return "";
  };

  const i9Details = json[1]["I-9_Details"][0];
  const MSADetails = json[2]["MSA_Details"][0];
  const W9details = json[3].W9[0];
  const Visadetails = json[4].Visa[0];
  const coi = json[5].coi[0];
  const csd = json[6].csd[0];

  // const handleDownloadImage = () => {
  //   const link = document.createElement("a");
  //   link.href = img;
  //   link.download = "Image.jpg";
  //   link.click();
  // };
  const handleFilter = () => {
    const filtered = json[0].Candidate_Details.filter((item) => {
      const nameMatch = item.Name.toLowerCase().includes(
        nameFilter.toLowerCase()
      );
      const emailMatch = item.Email.toLowerCase().includes(
        emailFilter.toLowerCase()
      );
      const countryMatch = item.country
        .toLowerCase()
        .includes(countryFilter.toLowerCase());

      return nameMatch && emailMatch && countryMatch;
    });

    setFilteredData(filtered);
  };
  const handleClearFilter = () => {
    setNameFilter("");
    setEmailFilter("");
    setCountryFilter("");
    setFilteredData(json[0].Candidate_Details);
  };
  const handleViewImage = () => {
    setIsDownloadPage(true);
  };

  const getDialogContent = () => {
    if (selectedButton === "i9") {
      return (
        <div className="i9-popup">
          <AiTwotoneFile onClick={handleViewImage}> </AiTwotoneFile>
          <p>Click icon for get your Download Page</p>
          <p>File Expiry Date: {i9Details.file_expiry_date}</p>
          <p>Remarks: {i9Details.remarks}</p>
          <div></div>
        </div>
      );
    } else if (selectedButton === "msa") {
      return (
        <div className="i9-popup">
          <AiTwotoneFile onClick={handleViewImage}> </AiTwotoneFile>
          <p>Click icon for get your Download Page</p>
          <p>File Expiry Date: {MSADetails.file_expiry_date}</p>
          <p>Remarks: {MSADetails.remarks}</p>
        </div>
      );
    } else if (selectedButton === "w9") {
      return (
        <div className="i9-popup">
          <AiTwotoneFile onClick={handleViewImage}> </AiTwotoneFile>
          <p>Click icon for get your Download Page</p>
          <p>File Expiry Date: {W9details.file_expiry_date}</p>
          <p>Remarks: {W9details.remarks}</p>
        </div>
      );
    } else if (selectedButton === "visa") {
      return (
        <div className="i9-popup">
          <AiTwotoneFile onClick={handleViewImage}> </AiTwotoneFile>
          <p>Click icon for get your Download Page</p>
          <p>File Expiry Date: {Visadetails.file_expiry_date}</p>
          <p>Remarks: {Visadetails.remarks}</p>
        </div>
      );
    } else if (selectedButton === "coi") {
      return (
        <div className="i9-popup">
          <AiTwotoneFile onClick={handleViewImage}> </AiTwotoneFile>
          <p>Click icon for get your Download Page</p>
          <p>File Expiry Date: {coi.file_expiry_date}</p>
          <p>Remarks: {coi.remarks}</p>
        </div>
      );
    } else if (selectedButton === "countersign") {
      return (
        <div className="i9-popup">
          <AiTwotoneFile onClick={handleViewImage}> </AiTwotoneFile>
          <p>Click icon for get your Download Page</p>
          <p>File Expiry Date: {csd.file_expiry_date}</p>
          <p>Remarks: {csd.remarks}</p>
        </div>
      );
    }

    return "";
  };

  return (
    <div>
      <div className="logo">
        <a href="/">
          <img
            src={vylogo}
            width="100px"
            alt="nothing"
            className="vysystemlogo"
          />
        </a>
      </div>
      <div>
        <nav>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li className="dropdown">
              <a href="/" className="dropbtn">
                Candidate
              </a>
              <div className="dropdown-content">
                <a href="/candidate-details">Candidate Creation</a>
                <a href="/candidate-filelist">Candidate list</a>
              </div>
            </li>
            <li>
              <a href="/" onClick={() => clearsession()}>
                Logout
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <Grid container>
        <Grid item xs={12} lg={12} sm={12}>
          <div className="candidate-heading">
            <h2>Candidate Details</h2>
          </div>

          <div className="filter-container">
            <div className="filter-item">
              <label htmlFor="name">Name</label>
              <TextField
                id="name"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="filter-item">
              <label htmlFor="email">Email</label>
              <TextField
                id="email"
                value={emailFilter}
                onChange={(e) => setEmailFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="filter-item">
              <label htmlFor="country">Country</label>
              <TextField
                id="country"
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="filter-item">
              <button onClick={handleFilter} className="filter-button">
                Filter
              </button>
            </div>
            <div className="filter-item">
              <button onClick={handleClearFilter} className="filter-button">
                Clear Filter
              </button>
            </div>
          </div>

          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  {Object.keys(json[0].Candidate_Details[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.Name}</td>
                      <td>{item.Email}</td>
                      <td>{item["Start Date"]}</td>
                      <td>{item["Client Name"]}</td>
                      <td>{item["Vendor Name"]}</td>
                      <td>
                        {item.country === "usa" && (
                          <img
                            className="flag"
                            src="https://flagcdn.com/h20/us.png"
                            width="10"
                            alt="United States"
                          />
                        )}
                        {item.country === "canada" && (
                          <img
                            className="flag"
                            src="https://flagcdn.com/ca.svg"
                            width="10"
                            alt="Canada"
                          />
                        )}
                        {item.country !== "usa" &&
                          item.country !== "canada" &&
                          item.country}
                      </td>
                      <td>{item.H1B}</td>
                      <td>{item["H1B Name"]}</td>
                      <td>
                        <button
                          className="i9-icon"
                          onClick={handleClickOpen("paper", "i9", index)}
                        >
                          I9
                        </button>
                      </td>
                      <td>
                        <button
                          className="i9-icon"
                          onClick={handleClickOpen("paper", "msa")}
                        >
                          MSA
                        </button>
                      </td>
                      <td>
                        <button
                          className="i9-icon"
                          onClick={handleClickOpen("paper", "w9")}
                        >
                          w9
                        </button>
                      </td>
                      <td>
                        <button
                          className="i9-icon"
                          onClick={handleClickOpen("paper", "visa")}
                        >
                          visa
                        </button>
                      </td>
                      <td>
                        <button
                          className="i9-icon"
                          onClick={handleClickOpen("paper", "coi")}
                        >
                          COI
                        </button>
                      </td>
                      <td>
                        <button
                          className="i9-icon"
                          onClick={handleClickOpen("paper", "countersign")}
                        >
                          csd
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={13} className="no-results-message">
                      <span className="table-err-msg">
                        Sorry, your search values are not found.
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <ReactPaginate
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(selected) => setCurrentPage(selected.selected)}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title" className="popup-title">
                  {getDialogTitle()}
                </DialogTitle>
                {isDownloadPage && (
                  <ArrowBackIcon
                    className="backIcon"
                    onClick={() => setIsDownloadPage(false)}
                  ></ArrowBackIcon>
                )}
                <CancelIcon
                  className="close-icon"
                  onClick={handleClose}
                ></CancelIcon>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    {isDownloadPage ? (
                      <div className="popup-image-div">
                        {/* <img src={img} alt="Image" className="popup-image" /> */}
                        <div className="pdf-div">
                          <embed src={ReactPdf} className="pdf-page" />
                        </div>
                        <a
                          href={ReactPdf}
                          download="Example-PDF-document"
                          rel="noreferrer"
                        >
                          {/* <Button
                            variant="contained"
                            // onClick={handleDownloadImage}
                            className="download-button"
                          >
                            <DownloadIcon></DownloadIcon>Download
                          </Button> */}
                        </a>
                      </div>
                    ) : (
                      getDialogContent()
                    )}
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FileList;
