import React from "react";

function Staticfunction() {
  const [logout, setlogout] = React.useState();
  const [otppopup, setotppopup] = React.useState(false);
  const [email, setemail] = React.useState();
  return {
    logout,
    setlogout,
    otppopup,
    setotppopup,
    email,
    setemail,
  };
}

export default Staticfunction;
