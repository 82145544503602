import React from "react";
import vylogo from "../image/vy-logo.png";
import { useFormik } from "formik";
import * as StyledDOM from "./Candidatestyle";
import * as Yup from "yup";
import { Grid } from "@mui/material";

function CanticateDetails() {
  const clearsession = () => {
    sessionStorage.clear("email");
  };
  const validationSchema = Yup.object({
    CandidateEmail: Yup.string()
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    CandidateName: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters")
      .max(25, "Name must be less than 25 characters"),

    ProjectStartDate: Yup.string().required(
      "Please select an ProjectStart date"
    ),
    ClientName: Yup.string().required("ClientName is required"),
    CountryCode: Yup.string().required("Please select any country"),
    VyVendorName: Yup.string()
      .required("VyVendorName is required")
      .min(3, "VyVendorName must be at least 3 characters")
      .max(25, "VyVendorName must be less than 25 characters"),

    H1BVendorornot: Yup.string()
      .oneOf(["Yes", "No"], "Please select Yes or No")
      .required("Please select an H1BVendorornot option"),

    // i9UploadFiles: Yup.mixed().test("file", "Please select a file", (value) => {
    //   if (value && value.length > 0) {
    //     return true;
    //   }
    //   return false;
    // }),
    // i9ExpiryDate: Yup.string().required("Please select an expiry date"),
    // i9Exhibit_B_Files: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // MSAUploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // MSAsignedDate: Yup.string().required("Please select an signedDate"),
    // MSAExhibit_A_Files: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // W9_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // W9_VoidCheck_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // W9_ACH_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // W9_DataFilled: Yup.string()
    //   .oneOf(["yes", "no"], "Please select Yes or No")
    //   .required("Please select an option"),
    // Visa_Document_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // Visa_Company: Yup.string()
    //   .required("Visa_Company Name is required")
    //   .min(3, "Visa_Company Name must be at least 3 characters")
    //   .max(25, "Visa_Company Name must be less than 25 characters"),

    // Visa_From_Date: Yup.string().required("Please select an Visa_From date"),
    // Visa_Expiry_Date: Yup.string().required(
    //   "Please select an Visa_Expiry date"
    // ),
    // COI_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // COI_Expiry_Date: Yup.string().required("Please select an COI_Expiry date"),
    // COI_Expiry_Type: Yup.string()
    //   .required("COI_Expiry_Type Name is required")
    //   .min(3, "COI_Expiry_Type Name must be at least 3 characters")
    //   .max(25, "COI_Expiry_Type Name must be less than 25 characters")
    //   .matches(/^[A-Za-z]+$/, "Name can only contain alphabetic characters"),
    // Contact_Info_Sheet_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // Counter_Signed_File_UploadFiles: Yup.mixed().test(
    //   "file",
    //   "Please select a file",
    //   (value) => {
    //     if (value && value.length > 0) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ),
    // Documentation_Completion: Yup.string().required(
    //   "Documentation_Completion Name is required"
    // ),
    // Remark: Yup.string()
    //   .required("Remark is required")
    //   .min(3, "Remark must be at least 3 characters")
    //   .max(100, "Remark  must be less than 25 characters"),
  });

  const validateForm = (values) => {
    const errors = {};

    if (values.H1BVendorornot === "No") {
      const nameValidation = Yup.string()
        .required("H1Bvendorname is required")
        .min(3, "H1Bvendorname must be at least 3 characters")
        .max(25, "H1Bvendorname must be less than 25 characters");

      try {
        nameValidation.validateSync(values.H1Bvendorname);
      } catch (error) {
        errors.H1Bvendorname = error.message;
      }
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      // Candidata_Details:
      CandidateEmail: "",
      CandidateName: "",
      ProjectStartDate: "",
      ClientName: "",
      VyVendorName: "",
      CountryCode: "",
      H1BVendorornot: "",
      H1Bvendorname: "",

      // i9_Details:
      i9UploadFiles: [],
      i9ExpiryDate: "",
      i9Exhibit_B_Files: [],

      // MSA_Details:
      MSAUploadFiles: [],
      MSAsignedDate: "",
      MSAExhibit_A_Files: [],

      // W9_Detalis:
      W9_UploadFiles: [],
      W9_VoidCheck_UploadFiles: [],
      W9_ACH_UploadFiles: [],
      W9_DataFilled: "",

      // Visa_Details:
      Visa_Document_UploadFiles: [],
      Visa_Company: "",
      Visa_From_Date: "",
      Visa_Expiry_Date: "",

      // COI_Deatails:
      COI_UploadFiles: [],
      COI_Expiry_Date: "",
      COI_Expiry_Type: "",
      Contact_Info_Sheet_UploadFiles: [],

      // Counter_sign:
      Counter_Signed_File_UploadFiles: [],
      Documentation_Completion: "",
      Remark: "",
    },

    validationSchema: validationSchema,
    validate: validateForm,
    onSubmit: async (values, { resetForm }) => {
      const {
        CandidateEmail,
        CandidateName,
        ProjectStartDate,
        ClientName,
        VyVendorName,
        CountryCode,
        H1BVendorornot,
        H1Bvendorname,
        i9UploadFiles,
        i9ExpiryDate,
        i9Exhibit_B_Files,
        MSAUploadFiles,
        MSAsignedDate,
        MSAExhibit_A_Files,
        W9_UploadFiles,
        W9_VoidCheck_UploadFiles,
        W9_ACH_UploadFiles,
        W9_DataFilled,
        Visa_Document_UploadFiles,
        Visa_Company,
        Visa_From_Date,
        Visa_Expiry_Date,
        COI_UploadFiles,
        COI_Expiry_Date,
        COI_Expiry_Type,
        Contact_Info_Sheet_UploadFiles,
        Counter_Signed_File_UploadFiles,
        Documentation_Completion,
        Remark,
      } = values;

      const getFileData = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      };

      const getTemporaryFiles = async (fileList) => {
        const temporaryFiles = [];
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          const fileData = await getFileData(file);
          temporaryFiles.push({ fileName: file.name, fileData: fileData });
        }
        return temporaryFiles;
      };

      const i9UploadFilesData = await getTemporaryFiles(i9UploadFiles);
      const i9Exhibit_B_FilesData = await getTemporaryFiles(i9Exhibit_B_Files);
      const MSAUploadFilesData = await getTemporaryFiles(MSAUploadFiles);
      const MSAExhibit_A_FilesData = await getTemporaryFiles(
        MSAExhibit_A_Files
      );
      const W9_UploadFilesData = await getTemporaryFiles(W9_UploadFiles);
      const W9_VoidCheck_UploadFilesData = await getTemporaryFiles(
        W9_VoidCheck_UploadFiles
      );
      const W9_ACH_UploadFilesData = await getTemporaryFiles(
        W9_ACH_UploadFiles
      );
      const Visa_Document_UploadFilesData = await getTemporaryFiles(
        Visa_Document_UploadFiles
      );
      const COI_UploadFilesData = await getTemporaryFiles(COI_UploadFiles);
      const Contact_Info_Sheet_UploadFilesData = await getTemporaryFiles(
        Contact_Info_Sheet_UploadFiles
      );
      const Counter_Signed_File_UploadFilesData = await getTemporaryFiles(
        Counter_Signed_File_UploadFiles
      );

      const Candidate_Details = [
        {
          CandidateEmail: CandidateEmail,
          CandidateName: CandidateName,
          ProjectStartDate: ProjectStartDate,
          ClientName: ClientName,
          VyVendorName: VyVendorName,
          CountryCode: CountryCode,
          H1BVendorornot: H1BVendorornot,
          H1Bvendorname: H1Bvendorname,
        },
      ];

      const i9_Details = [
        {
          i9UploadFiles: i9UploadFilesData,
          i9ExpiryDate: i9ExpiryDate,
          i9Exhibit_B_Files: i9Exhibit_B_FilesData,
        },
      ];
      const MSA_Details = [
        {
          MSAUploadFiles: MSAUploadFilesData,
          MSAsignedDate: MSAsignedDate,
          MSAExhibit_A_Files: MSAExhibit_A_FilesData,
        },
      ];
      const W9_Details = [
        {
          W9_UploadFiles: W9_UploadFilesData,
          W9_VoidCheck_UploadFiles: W9_VoidCheck_UploadFilesData,
          W9_ACH_UploadFiles: W9_ACH_UploadFilesData,
          W9_DataFilled: W9_DataFilled,
        },
      ];
      const Visa_Details = [
        {
          Visa_Document_UploadFiles: Visa_Document_UploadFilesData,
          Visa_Company: Visa_Company,
          Visa_From_Date: Visa_From_Date,
          Visa_Expiry_Date: Visa_Expiry_Date,
        },
      ];
      const COI_Details = [
        {
          COI_UploadFiles: COI_UploadFilesData,
          COI_Expiry_Date: COI_Expiry_Date,
          COI_Expiry_Type: COI_Expiry_Type,
          Contact_Info_Sheet_UploadFiles: Contact_Info_Sheet_UploadFilesData,
        },
      ];
      const Counter_Signed_Details = [
        {
          Counter_Signed_File_UploadFiles: Counter_Signed_File_UploadFilesData,
          Documentation_Completion: Documentation_Completion,
          Remark: Remark,
        },
      ];
      const formDatas = {
        Candidate_Details,
        i9_Details,
        MSA_Details,
        W9_Details,
        Visa_Details,
        COI_Details,
        Counter_Signed_Details,
      };

      console.log("formDatas", formDatas);

      // console.log("formDatas", JSON.stringify(formDatas, null, 2));
      // resetForm({ values: "" });
    },
  });
  const AtoZPattern = (e) => {
    const pattern = /^[A-Za-z()\s\,]+$/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="top">
        <div className="logo">
          <a href="/">
            <img src={vylogo} alt="nothing" className="vysystemlogo" />
          </a>
        </div>
        <div>
          <nav>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="dropdown">
                <a className="dropbtn">Candidate</a>
                <div className="dropdown-content">
                  <a href="/candidate-details">Candidate Creation</a>
                  <a href="/candidate-filelist">Candidate list</a>
                </div>
              </li>
              <li>
                <a href="/" onClick={() => clearsession()}>
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <StyledDOM.MainDivContainer container>
        <Grid item xs={12} lg={12} sm={12} style={{ marginLeft: "3%" }}>
          <StyledDOM.MainHeadingh3>Candidate Details</StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div>
              <StyledDOM.Styledheadings>
                Candidate Email ID (Pored in IRMT)
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledinputEmailContainer
                type="email"
                placeholder="Enter Your IRMT email address"
                name="CandidateEmail"
                className="candidateemail"
                value={formik.values.CandidateEmail}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const lowercaseEmail = event.target.value.toLowerCase();
                  formik.handleChange(event);
                  formik.setFieldValue("CandidateEmail", lowercaseEmail);
                }}
              />
              {formik.touched.CandidateEmail && formik.errors.CandidateEmail ? (
                <div className="error-message">
                  {formik.errors.CandidateEmail}
                </div>
              ) : null}
            </div>

            <div>
              <StyledDOM.Styledheadings>
                Candidate Name (Pored in IRMT)
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledinputTextContainer
                type="text"
                placeholder="Enter Your IRMT Name"
                id="outlined-basic"
                variant="outlined"
                name="CandidateName"
                onKeyPress={AtoZPattern}
                onBlur={formik.handleBlur}
                value={formik.values.CandidateName}
                onChange={formik.handleChange}
              />
              {formik.touched.CandidateName && formik.errors.CandidateName ? (
                <div className="error-message">
                  {formik.errors.CandidateName}
                </div>
              ) : null}
            </div>

            <StyledDOM.StyledProjectstartDateDiv className="project-start-date">
              <StyledDOM.Styledheadings>
                Project Start Date
              </StyledDOM.Styledheadings>
              <div className="i-9">
                <div className="file-input-container">
                  <StyledDOM.StyledDateInput
                    type="date"
                    name="ProjectStartDate"
                    placeholder="..."
                    onBlur={formik.handleBlur}
                    value={formik.values.ProjectStartDate}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.touched.ProjectStartDate &&
                formik.errors.ProjectStartDate ? (
                  <div className="error-message">
                    {formik.errors.ProjectStartDate}
                  </div>
                ) : null}
              </div>
            </StyledDOM.StyledProjectstartDateDiv>

            <div>
              <StyledDOM.Styledheadings>Client Name</StyledDOM.Styledheadings>
              <StyledDOM.StyledSelectOption
                id="demo-select-small"
                name="ClientName"
                value={formik.values.ClientName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value="" disabled hidden>
                  Select an option
                </option>
                <option value={"CodeNatives"} className="custom-select">
                  CodeNatives
                </option>
                <option value={"VyBog"}>VyBog</option>
                <option value={"VySystem"}>VySystem</option>
              </StyledDOM.StyledSelectOption>

              {formik.touched.ClientName && formik.errors.ClientName ? (
                <div className="error-message">{formik.errors.ClientName}</div>
              ) : null}
            </div>

            <div>
              <StyledDOM.Styledheadings>
                Vy Vendor Name
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledinputTextContainer
                type="text"
                name="VyVendorName"
                placeholder="Enter Vy Vendor Name"
                onKeyPress={AtoZPattern}
                value={formik.values.VyVendorName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.VyVendorName && formik.errors.VyVendorName ? (
                <div className="error-message">
                  {formik.errors.VyVendorName}
                </div>
              ) : null}
            </div>
            <div>
              <StyledDOM.Styledheadings>Country</StyledDOM.Styledheadings>
              <StyledDOM.StyledSelectOption
                name="CountryCode"
                value={formik.values.CountryCode}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value="" disabled hidden>
                  Select an option
                </option>
                <option value="01">USA</option>
                <option value="10">Canada</option>
              </StyledDOM.StyledSelectOption>
              {formik.touched.CountryCode && formik.errors.CountryCode ? (
                <div className="error-message">{formik.errors.CountryCode}</div>
              ) : null}
            </div>
            <div>
              <StyledDOM.Styledheadings>
                Are they H1B vendor
              </StyledDOM.Styledheadings>

              <StyledDOM.StyledSelectOption
                name="H1BVendorornot"
                value={formik.values.H1BVendorornot}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value="" disabled hidden>
                  Select an option
                </option>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </StyledDOM.StyledSelectOption>
              {formik.touched.H1BVendorornot && formik.errors.H1BVendorornot ? (
                <div className="error-message">
                  {formik.errors.H1BVendorornot}
                </div>
              ) : null}
            </div>

            {formik.values.H1BVendorornot === "No" ? (
              <div>
                <StyledDOM.Styledheadings>
                  No Means who is H1B vendor:
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledinputTextContainer
                  type="text"
                  name="H1Bvendorname"
                  onBlur={formik.handleBlur}
                  onKeyPress={AtoZPattern}
                  value={formik.values.H1Bvendorname}
                  onChange={formik.handleChange}
                />
                {formik.touched.H1Bvendorname && formik.errors.H1Bvendorname ? (
                  <div className="error-message">
                    {formik.errors.H1Bvendorname}
                  </div>
                ) : null}
              </div>
            ) : null}
          </StyledDOM.StyledCandidateMainDiv>

          <StyledDOM.MainHeadingh3>I-9 Form Deatails</StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div>
              <div className="i-9">
                <StyledDOM.Styledheadings>
                  {" "}
                  I-9 Form Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label> choose file</label>
                  <input
                    multiple
                    type="file"
                    id="file"
                    placeholder="choose file"
                    name="i9UploadFiles"
                    onChange={(e) =>
                      formik.setFieldValue("i9UploadFiles", e.target.files)
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.i9UploadFiles && formik.touched.i9UploadFiles ? (
                  <div className="error-message">
                    {formik.errors.i9UploadFiles}
                  </div>
                ) : null}
                {formik.values.i9UploadFiles.length > 0 && (
                  <StyledDOM.StyledOrderList>
                    {Array.from(formik.values.i9UploadFiles).map(
                      (file, index) => (
                        <li key={index} className="fileupload">
                          {file.name}/ &nbsp;{" "}
                        </li>
                      )
                    )}
                  </StyledDOM.StyledOrderList>
                )}
              </div>
            </div>
            <div className="i-9">
              <StyledDOM.Styledheadings> I-9 Expiry</StyledDOM.Styledheadings>
              <div className="file-input-container">
                <StyledDOM.StyledDateInput
                  type="date"
                  placeholder="..."
                  name="i9ExpiryDate"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.i9ExpiryDate && formik.touched.i9ExpiryDate ? (
                <div className="error-message">
                  {formik.errors.i9ExpiryDate}
                </div>
              ) : null}
            </div>
            <div className="i-9">
              <StyledDOM.Styledheadings>
                {" "}
                exhibit-b - vendor name/h1B filled proper
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file-input"
                  name="i9Exhibit_B_Files"
                  placeholder="choose file"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue("i9Exhibit_B_Files", e.target.files)
                  }
                />
              </div>
              {formik.errors.i9Exhibit_B_Files &&
              formik.touched.i9Exhibit_B_Files ? (
                <div className="error-message">
                  {formik.errors.i9Exhibit_B_Files}
                </div>
              ) : null}
              {formik.values.i9Exhibit_B_Files.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.i9Exhibit_B_Files).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>
          </StyledDOM.StyledCandidateMainDiv>
          <br />
          <br />
          <StyledDOM.MainHeadingh3>MSA Form Deatails</StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div className="i-9">
              <StyledDOM.Styledheadings> MSA Upload</StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file"
                  placeholder="choose file"
                  name="MSAUploadFiles"
                  onChange={(e) =>
                    formik.setFieldValue("MSAUploadFiles", e.target.files)
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.MSAUploadFiles &&
                formik.touched.MSAUploadFiles && (
                  <div className="error-message">
                    {formik.errors.MSAUploadFiles}
                  </div>
                )}
              {formik.values.MSAUploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.MSAUploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                MSA sigened date
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <StyledDOM.StyledDateInput
                  type="date"
                  placeholder="..."
                  name="MSAsignedDate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.MSAsignedDate && formik.touched.MSAsignedDate ? (
                <div className="error-message">
                  {formik.errors.MSAsignedDate}
                </div>
              ) : null}
            </div>
            <div className="i-9">
              <StyledDOM.Styledheadings>
                Exhibit-A/PO Upload
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file-input"
                  name="MSAExhibit_A_Files"
                  placeholder="choose file"
                  onChange={(e) =>
                    formik.setFieldValue("MSAExhibit_A_Files", e.target.files)
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.MSAExhibit_A_Files &&
                formik.touched.MSAExhibit_A_Files && (
                  <div className="error-message">
                    {formik.errors.MSAExhibit_A_Files}
                  </div>
                )}
              {formik.values.MSAExhibit_A_Files.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.MSAExhibit_A_Files).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>
          </StyledDOM.StyledCandidateMainDiv>
          <br />
          <br />
          <StyledDOM.MainHeadingh3>W9 Form Deatails</StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div className="i-9">
              <StyledDOM.Styledheadings>W9 Upload</StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file"
                  placeholder="choose file"
                  name="W9_UploadFiles"
                  onChange={(e) =>
                    formik.setFieldValue("W9_UploadFiles", e.target.files)
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.W9_UploadFiles &&
                formik.touched.W9_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.W9_UploadFiles}
                  </div>
                )}
              {formik.values.W9_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.W9_UploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                {" "}
                VOID Check Upload
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file-input"
                  name="W9_VoidCheck_UploadFiles"
                  placeholder="choose file"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "W9_VoidCheck_UploadFiles",
                      e.target.files
                    )
                  }
                />
              </div>
              {formik.errors.W9_VoidCheck_UploadFiles &&
                formik.touched.W9_VoidCheck_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.W9_VoidCheck_UploadFiles}
                  </div>
                )}
              {formik.values.W9_VoidCheck_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.W9_VoidCheck_UploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>
            <div className="i-9">
              <StyledDOM.Styledheadings> ACH-Upload</StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file-input"
                  name="W9_ACH_UploadFiles"
                  placeholder="choose file"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue("W9_ACH_UploadFiles", e.target.files)
                  }
                />
              </div>
              {formik.errors.W9_ACH_UploadFiles &&
                formik.touched.W9_ACH_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.W9_ACH_UploadFiles}
                  </div>
                )}
              {formik.values.W9_ACH_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.W9_ACH_UploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                All Data Filled in ACH? With Out blank lines
              </StyledDOM.Styledheadings>

              <StyledDOM.StyledSelectOption
                id="demo-select-small"
                name="W9_DataFilled"
                value={formik.values.W9_DataFilled}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value="" disabled hidden>
                  Select an option
                </option>
                <option value={"yes"}>yes</option>
                <option value={"no"}>No</option>
              </StyledDOM.StyledSelectOption>

              {formik.errors.W9_DataFilled && formik.touched.W9_DataFilled && (
                <div className="error-message">
                  {formik.errors.W9_DataFilled}
                </div>
              )}
            </div>
          </StyledDOM.StyledCandidateMainDiv>
          <br />
          <br />

          <StyledDOM.MainHeadingh3>Visa Type Upload</StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div className="i-9">
              <StyledDOM.Styledheadings>
                Visa Type Document Upload
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file"
                  placeholder="choose file"
                  name="Visa_Document_UploadFiles"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "Visa_Document_UploadFiles",
                      e.target.files
                    )
                  }
                />
              </div>
              {formik.errors.Visa_Document_UploadFiles &&
                formik.touched.Visa_Document_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.Visa_Document_UploadFiles}
                  </div>
                )}
              {formik.values.Visa_Document_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.Visa_Document_UploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings style={{ marginTop: "-13px" }}>
                {" "}
                Visa Company/this has to be crosed verified with the above H1B
                Company.
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledinputTextContainer
                id="outlined-basic"
                name="Visa_Company"
                placeholder="Enter Visa Company Name"
                onKeyPress={AtoZPattern}
                value={formik.values.Visa_Company}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.Visa_Company && formik.touched.Visa_Company && (
                <div className="error-message">
                  {formik.errors.Visa_Company}
                </div>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                Visa From Date
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <StyledDOM.StyledDateInput
                  type="date"
                  name="Visa_From_Date"
                  placeholder="..."
                  value={formik.values.Visa_From_Date}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.Visa_From_Date &&
                formik.touched.Visa_From_Date && (
                  <div className="error-message">
                    {formik.errors.Visa_From_Date}
                  </div>
                )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                Visa Expiry/End Date
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <StyledDOM.StyledDateInput
                  type="date"
                  name="Visa_Expiry_Date"
                  placeholder="..."
                  value={formik.values.Visa_Expiry_Date}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.Visa_Expiry_Date &&
                formik.touched.Visa_Expiry_Date && (
                  <div className="error-message">
                    {formik.errors.Visa_Expiry_Date}
                  </div>
                )}
            </div>
          </StyledDOM.StyledCandidateMainDiv>
          <br />
          <br />

          <StyledDOM.MainHeadingh3>
            Insuraunce Type of Certificate Upload
          </StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div className="i-9">
              <StyledDOM.Styledheadings>
                Certificate of insuraunce Upload
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file"
                  placeholder="choose file"
                  name="COI_UploadFiles"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue("COI_UploadFiles", e.target.files)
                  }
                />
              </div>
              {formik.errors.COI_UploadFiles &&
                formik.touched.COI_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.COI_UploadFiles}
                  </div>
                )}
              {formik.values.COI_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.COI_UploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                COI Expiry least
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <StyledDOM.StyledDateInput
                  type="date"
                  name="COI_Expiry_Date"
                  placeholder="..."
                  value={formik.values.COI_Expiry_Date}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.COI_Expiry_Date &&
                formik.touched.COI_Expiry_Date && (
                  <div className="error-message">
                    {formik.errors.COI_Expiry_Date}
                  </div>
                )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                Contact info Sheet Upload
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file"
                  placeholder="choose file"
                  name="Contact_Info_Sheet_UploadFiles"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "Contact_Info_Sheet_UploadFiles",
                      e.target.files
                    )
                  }
                />
              </div>
              {formik.errors.Contact_Info_Sheet_UploadFiles &&
                formik.touched.Contact_Info_Sheet_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.Contact_Info_Sheet_UploadFiles}
                  </div>
                )}
              {formik.values.Contact_Info_Sheet_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(formik.values.Contact_Info_Sheet_UploadFiles).map(
                    (file, index) => (
                      <li key={index} className="fileupload">
                        {file.name}/ &nbsp;{" "}
                      </li>
                    )
                  )}
                </StyledDOM.StyledOrderList>
              )}
            </div>
            <div className="i-9">
              <StyledDOM.Styledheadings>
                Type of insuraunce-Nearing Expiry
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledTextArea
                id="outlined-basic"
                name="COI_Expiry_Type"
                onKeyPress={AtoZPattern}
                value={formik.values.COI_Expiry_Type}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.COI_Expiry_Type &&
                formik.touched.COI_Expiry_Type && (
                  <div className="error-message">
                    {formik.errors.COI_Expiry_Type}
                  </div>
                )}
            </div>
          </StyledDOM.StyledCandidateMainDiv>
          <br />
          <br />

          <StyledDOM.MainHeadingh3>
            Countersigned Type Upload
          </StyledDOM.MainHeadingh3>
          <StyledDOM.StyledCandidateMainDiv className="gird-control">
            <div className="i-9">
              <StyledDOM.Styledheadings>
                Countersigned from Vy to Vendor- Upload
              </StyledDOM.Styledheadings>
              <div className="file-input-container">
                <label> choose file</label>
                <input
                  multiple
                  type="file"
                  id="file"
                  placeholder="choose file"
                  name="Counter_Signed_File_UploadFiles"
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "Counter_Signed_File_UploadFiles",
                      e.target.files
                    )
                  }
                />
              </div>
              {formik.errors.Counter_Signed_File_UploadFiles &&
                formik.touched.Counter_Signed_File_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.Counter_Signed_File_UploadFiles}
                  </div>
                )}
              {formik.values.Counter_Signed_File_UploadFiles.length > 0 && (
                <StyledDOM.StyledOrderList>
                  {Array.from(
                    formik.values.Counter_Signed_File_UploadFiles
                  ).map((file, index) => (
                    <li key={index} className="fileupload">
                      {file.name}/ &nbsp;{" "}
                    </li>
                  ))}
                </StyledDOM.StyledOrderList>
              )}
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                Documentation Completion{" "}
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledinputTextContainer
                type="text"
                name="Documentation_Completion"
                value={formik.values.Documentation_Completion}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </div>

            <div className="i-9">
              <StyledDOM.Styledheadings>
                Remark/What's pending/Why correct status
              </StyledDOM.Styledheadings>
              <StyledDOM.StyledTextArea
                id="outlined-multiline-flexible"
                multiline
                maxRows={6}
                onKeyPress={AtoZPattern}
                value={formik.values.Remark}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("Remark", e.target.value)}
              />
              {formik.errors.Remark && formik.touched.Remark && (
                <div className="error-message">{formik.errors.Remark}</div>
              )}
            </div>
          </StyledDOM.StyledCandidateMainDiv>
          <StyledDOM.StyledButtonDiv>
            <StyledDOM.StyledFileUploadButton
              className="upload-button"
              onClick={formik.handleSubmit}
              type="submit"
            >
              Upload
            </StyledDOM.StyledFileUploadButton>
          </StyledDOM.StyledButtonDiv>
        </Grid>
      </StyledDOM.MainDivContainer>
    </>
  );
}

export default CanticateDetails;
