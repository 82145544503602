import React from "react";
import "../styles/login.css";
import vylogo from "../image/vy-logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
// import axios from "axios";

import Otppage from "../OTP Page/Otp";
function Login(props) {
  const { otppopup, setotppopup, setemail, email } = props || {};

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      setemail(formik.values.email);
      // axios
      //   .post("/api/sendOTP", { email: values.email })
      //   .then((response) => {
      //     if (response.status === 200) {

      setotppopup(true);
      //       }
      //     })
      //     .catch((error) => {
      //       console.error(error);
      //     });
    },
  });
  const emailprop = formik.values.email;

  const diologprops = {
    otppopup,
    emailprop,
    email,
  };

  return (
    <div>
      <div className="login">
        {otppopup ? (
          <Otppage {...diologprops} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="Login-form">
            <img src={vylogo} className="vylogo" alt="nothing"></img>
            <h1 className="login-heading">Login Your Account</h1>
            <div></div>
            <br />
            <div>
              <label>Email</label>
              <input
                id="email"
                type="email"
                className="Login-email"
                onChange={(event) => {
                  const lowercaseEmail = event.target.value.toLowerCase();
                  formik.handleChange(event);
                  formik.setFieldValue("email", lowercaseEmail);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                placeholder="Enter your email address"
              />
              <br />
              {formik.touched.email && formik.errors.email ? (
                <div className="errormsg">{formik.errors.email}</div>
              ) : null}
            </div>
            <input type="submit" value="Login" />
            <br />
            <br />
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
