import React from "react";
import vylogo from "../image/vy-logo.png";
import Pinkfilelogo from "../image/icons8-folder-material-rounded-310.png";
import Bluefilelogo from "../image/icons8-folder-310.png";
import Orangefilelogo from "../image/orange.png";
import "../styles/dashboard.css";
function Dashboard() {
  const clearsession = () => {
    sessionStorage.clear("email");
  };

  return (
    <div className="header">
      <div className="top">
        <div className="logo">
          <a href="/">
            <img
              src={vylogo}
              width="100px"
              alt="nothing"
              className="vysystemlogo"
            />
          </a>
        </div>

        <div className="menu">
          <ul>
            <li>
              <a className="active" href="/">
                HOME
              </a>
            </li>
            <li className="dropdown">
              <a className="dropbtn">Candidate</a>
              <div className="dropdown-content">
                <a href="/candidate-details">Candidate Creation</a>
                <a href="/candidate-filelist">Candidate list</a>
              </div>
            </li>

            <li>
              <a href="/" onClick={() => clearsession()}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="first box">
          <img src={Pinkfilelogo} alt="nothing" width="50" height="50" />
          <div className="file-upload">
            <h1>I-9</h1>
            <p> file upload</p>
            <span>868</span>
          </div>
        </div>

        <div className="second box">
          <img src={Bluefilelogo} alt="nothing" width="50" height="50" />
          <div className="file-upload">
            <h1>MSA</h1>
            file upload
            <br />
            <span>534</span>
          </div>
        </div>

        <div className="third box">
          <img src={Orangefilelogo} alt="nothing" width="50" height="50" />
          <div className="file-upload">
            <h1>W9</h1>
            file upload
            <br />
            <span>645</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="fourth box">
          <img src={Pinkfilelogo} alt="nothing" width="50" height="50" />
          <div className="file-upload">
            <h1>Visa</h1>
            <p> file upload</p>
            <span>758</span>
          </div>
        </div>
        <div className="fifth box">
          <img src={Bluefilelogo} alt="nothing" width="50" height="50" />
          <div className="file-upload">
            <h2>Insurance</h2>
            <p> file upload</p>
            <span>802</span>
          </div>
        </div>
        <div className="sixth box">
          <img src={Orangefilelogo} alt="nothing" width="50" height="50" />
          <div className="file-upload">
            <h2>Countersign</h2>
            <p> file upload</p>
            <span>868</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
