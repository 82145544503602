import React from "react";
import Button from "@mui/material/Button";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller, useForm } from "react-hook-form";
import { Box, FormHelperText, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../styles/otp.css";

const Otppage = (props) => {
  const [staticOtp, setStaticOtp] = React.useState("1234");
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();

  const { email } = props || {};

  const { control, handleSubmit } = useForm({
    defaultValues: {
      otp: "",
    },
  });
  const [resendCount, setResendCount] = React.useState(0);

  const onSubmit = (data) => {
    if (data.otp === staticOtp) {
      sessionStorage.setItem("email", email);
      navigate("/dashboard");
    } else {
      setError(true);
    }
    // try {
    //   const response = await fetch("/api/resend-otp", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ email: "" }),
    //   });
    //   if (response.ok) {
    //     <h1>your otp verified Sucessfully</h1>;
    //   } else {
    //     <h1>Wrong Otp</h1>;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOtpChange = (value, onChange) => {
    if (/^\d*$/.test(value)) {
      onChange(value);
    }
  };

  const handleResendOtp = () => {
    setResendCount(resendCount + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="otpform">
      <h1 className="heading">Enter Your OTP</h1>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Controller
            name="otp"
            control={control}
            rules={{ validate: (value) => value.length === 4 }}
            render={({ field, fieldState }) => (
              <Box>
                <MuiOtpInput
                  sx={{ gap: 1 }}
                  {...field}
                  length={4}
                  onChange={(value) => handleOtpChange(value, field.onChange)}
                  TextFieldsProps={{
                    autoComplete: "off",
                  }}
                  autoFocus={true}
                />
                <div>
                  <h4 className="info">Check your email for the OTP</h4>
                  {fieldState.invalid ? (
                    <FormHelperText error>OTP invalid</FormHelperText>
                  ) : null}
                  {error && <FormHelperText error>Wrong OTP</FormHelperText>}
                  <Button
                    className="resendotp"
                    variant="text"
                    sx={{ mt: 2 }}
                    onClick={handleResendOtp}
                    disabled={resendCount >= 3}
                    color="success"
                  >
                    Resend OTP?
                  </Button>
                </div>
              </Box>
            )}
          />
        </Grid>
        {/* <Grid item>
          {resendCount >= 3 && (
            <p className="resend-limit">You have reached the resend limit.</p>
          )}
        </Grid> */}

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          className="verifybutton"
        >
          Verify
        </Button>
      </Grid>
    </form>
  );
};

export default Otppage;
