/* eslint-disable no-unused-expressions */
import { styled } from "@mui/material/styles";
import {   Grid } from "@mui/material";

('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');


export const MainDivContainer = styled(Grid)`
height:100vh;
width:100%;

`
export const MainHeadingh3 = styled("h3")`
text-align: center;
font-size: 28px;
font-weight: 600;
font-family: sans-serif;
margin-top: 25px;

`
export const StyledinputEmailContainer = styled("input")`
input[type=email],{
    width: 80%;
    height:0;
    padding: 17px 5px;
    border: 1px solid #181818;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 17px;
    &:focus {
        border-color: green;
    outline: 2px solid green;
      }

}
`


export const StyledinputTextContainer = styled("input")`
input[type=text],{
    width: 80%;
    height:0;
    padding: 17px 5px;
    border: 1px solid #181818;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 17px;
    &:focus {
        border-color: green;
        outline: 2px solid green;
      }
}
`
export const StyledDateInput = styled("input")`

    width: 93%;
    height: 20px;
    outline: none;
    border: none;
    font-size: 13px;
  
&:focus {
    border-color: green;
}
`

export const StyledCandidateMainDiv = styled("div")`

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;

    @media only screen and (max-width: 1025px) {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-lg-12.css-1idn90j-MuiGrid-root {
        overflow-x: hidden !important;
    }
}
    @media only screen and (max-width: 820px) {
        display: grid;
    grid-template-columns: 1fr 1fr ;
    }
    @media only screen and (max-width: 520px) {
        display: grid;
    grid-template-columns: 1fr  ;
    }
    .i-9 {
        margin:0px !important;
    }

    .file-input-container {
        position: relative;
        width: 270px;
        height: 25px;
        border: 1px solid #ccc;
        border-radius: 5px;
        overflow: hidden;
        width: 80%;
        padding: 5px 5px;
        border: 1px solid #181818;
        border-radius: 5px;
        margin-top: 6px;
        margin-bottom: 10px;
        font-size: 17px;
    
        &:focus-within {
            border-color:green;
            outline:2px solid green
        }
   
   
      
    }
   `
export const Styledheadings=styled("h3")`
color: grey;
font-size: 15px;
@media only screen and (max-width: 1170px) {
    font-size:13px;

}

`

export const StyledProjectstartDateDiv = styled("div")`
.i-9 {
    margin: 24px 0px 0!important;
   
}

.file-input-container {
    position: relative;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px 5px;
    border: 1px solid #181818;
    border-radius: 5px;
    font-size: 17px;
   
    
}



`
export const StyledSelectOption=styled("select")`
width: 83%;
height: 40px;
padding: 0px 5px;
border: 1px solid #181818;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 10px;
    &:focus {
        border-color: green;
    outline: 2px solid green;
      }

`
export const StyledTextArea=styled("textarea")`
width: 80%;
height: 100px;
resize: none;
border-radius: 5px;
border-color: #000;
padding: 10px;
font-size: 15px;
font-family: system-ui;
`
export const StyledFileUploadButton=styled("button")`

background: linear-gradient(166deg, #f3f4f2, #c2c3c1);
color: #000 !important;
text-transform: capitalize !important;
font-size: 13px !important;
font-weight: 700 !important;
margin: 0 !important;
padding: 11px 31px !important;
border-radius: 6px;
cursor: pointer


`
export const StyledButtonDiv=styled("div")`
text-align: center;
margin-bottom: 10px;
    margin-top: 20px;
`
export const StyledOrderList=styled("ol")`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

`



  